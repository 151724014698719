var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": _vm.company.name,
      "sub-title": _vm.$route.meta.subTitle || "Company Management",
      "category": "Company",
      "category-logo": "fa-users"
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "company-view",
      "tabs": _vm.tabs,
      "loading": _vm.isLoading
    }
  }, [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }