<template>
  <div>
    <PageHeader :title="company.name" :sub-title="$route.meta.subTitle || `Company Management`" category="Company" category-logo="fa-users" />
    <TabView route-name="company-view" :tabs="tabs" :loading="isLoading">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'CompanyView',
  components: {
    PageHeader,
    TabView
  },
  data() {
    return {
      isLoading: false,
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      loadingAction: 'company/loadingAction'
    }),
    tabs() {
      return [
        {
          name: 'overview',
          route: 'company-view',
          icon: 'fa-cog'
        },
        {
          name: 'users',
          route: 'company-users-list',
          icon: 'fa-users'
        },
        {
          name: 'roles',
          route: 'company-roles-list',
          icon: 'fa-user-gear'
        },
        {
          name: 'permissions',
          route: 'company-permissions-list',
          icon: 'fa-user-lock'
        },
        {
          name: 'activity log',
          route: 'company-log',
          icon: 'fa-clipboard'
        }
      ];
    }
  },
  async created() {
    this.isLoading = true;
    await this.getCompany({ id: this.$auth.companyId });
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getCompany: 'company/get'
    })
  }
};
</script>
